@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Website theme colors, fonts, and other styles */
:root {
  --logo-blue: #006BE6;
  --bkg-blue: #0075FF;
  --white: white;
  --logo-blue-light: #2F80ED;
  --link-blue: #18A0FB;
  --soft-blue: #7FB4F2;
  --light-dark-blue: #2D9CDB;  

  --font-family: 'Rubik', sans-serif; 
  --font-logo: 'Comfortaa', sans-serif;

  --fs-logo: 3rem;
  --fs-h1: 2.5rem;
  --fs-h2: 2rem;
  --fs-h3: 1.75rem;
  --fs-p: 1rem;

  --fw-regular: 400;
  --fw-light: 300;
  --fw-medium: 500;
  --fw-bold: 800;
}

@media (min-width: 544px) {  
  h3 {font-size:1rem;} /*1rem = 16px*/
}

.chart-container {
  position: relative;
  margin: auto;
  max-width: 600px;
  max-height: 400px;
}

/* Website font theme */
.logo{
  font-family: var(--font-logo);  
}

.logo-blue{
  font-family: var(--font-logo);
  font-weight: 900;
  color: var(--logo-blue);
}

.logo-h1{
  font-family: var(--font-logo);  
  font-size: var(--fs-h1);
}

.logo-h2{
  font-family: var(--font-logo);  
  font-size: var(--fs-h2);
}

.logo-blue-h1{
  font-family: var(--font-logo);
  font-weight: 900;
  color: var(--logo-blue);
  font-size: var(--fs-h1);
}

.logo-blue-h2{
  font-family: var(--font-logo);
  font-weight: 900;
  color: var(--logo-blue);
  font-size: var(--fs-h2);
}

.logo-h0{
  font-family: var(--font-logo);  
  font-size: var(--fs-logo);
}

.logo-blue-h0{
  font-family: var(--font-logo);
  font-weight: 900;
  color: var(--logo-blue);
  font-size: var(--fs-logo);
}

h1{
  font-family: var(--font-family);  
  font-size: var(--fs-h1);
}

.h1-bold{
  font-family: var(--font-family);
  font-size: var(--fs-h1);
  font-weight: var(--fw-bold);
}

.h1-white{
  font-family: var(--font-family);  
  font-size: var(--fs-h1);
  color: white;  
}

h2{
  font-family:  var(--font-family);    
  font-size: var(--fs-h2);
}

h3{
  font-family: var(--font-family);
  font-size: var(--fs-h3);
}

p{
  font-family: var(--font-family);
  font-size: var(--fs-p);
}

.p-white{
  font-family: var(--font-family);
  font-size: var(--fs-p);
  color: white;  
}

.bold{
  font-weight: var(--fw-bold);
}

.bkg-blue{
  background-color: var(--bkg-blue);
}

.bkg-white{
  background-color: var(--white);
}

.white-text{
  color: var(--white);
}

 /* unvisited link */
 a:link {
  color: var(--logo-blue);  
  font-family: var(--font-family);
}

/* visited link */
a:visited {
  color: var(--logo-blue);
  font-family: var(--font-family);
}

/* mouse over link */
a:hover {
  color: var(--logo-blue);
  text-decoration: underline;
  font-family: var(--font-family);
}

/* selected link */
a:active {
  color: var(--logo-blue);
  font-family: var(--font-family);
} 

/* sets table font to font-family style */
td
{
    font-family: var(--font-family);
}

/* sets underline and font when nav is active in section */
.nav-item > .active {
  border-bottom: 1px solid #333;
  font-family: var(--font-family);
}

// Example usage:
@include media-breakpoint-down(sm) {
  .no-lr-padding-sm {    
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@include media-breakpoint-down(sm) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}